import React, { createContext, useContext, useState } from "react";

// Active
const ActiveContext = createContext();
const ActiveUpdateContext = createContext();

export const useActiveContext = () => {
    return useContext(ActiveContext);
};

export const useActiveUpdateContext = () => {
    return useContext(ActiveUpdateContext);
};

// Scroll
const ScrollContext = createContext();
const ScrollUpdateContext = createContext();

export const useScrollContext = () => {
    return useContext(ScrollContext);
};

export const useScrollUpdateContext = () => {
    return useContext(ScrollUpdateContext);
};

// Context Provider
const ContextProvider = ({ children }) => {
    const [active, setActive] = useState("");
const [scroll, setScroll] = useState(500);
    return (
        <ActiveContext.Provider value={active}>
            <ActiveUpdateContext.Provider value={setActive}>
                <ScrollContext.Provider value={scroll}>
                    <ScrollUpdateContext.Provider value={setScroll}>
                        {children}
                    </ScrollUpdateContext.Provider>
                </ScrollContext.Provider>
            </ActiveUpdateContext.Provider>
        </ActiveContext.Provider>
    );
};

export default ContextProvider;
