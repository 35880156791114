import React from "react";
import GlobalState from "../state/GlobalState";
import Seo from "../components/seo/Seo";
import Loading from "../components/loading/Loading";

const Layout = ({ children }) => {
    return (
        <GlobalState>
            <Seo />
            <Loading />
            {children}
        </GlobalState>
    );
};

export default Layout;
