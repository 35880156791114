import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const Seo = () => {
    const {
        site: {
            siteMetadata: { siteUrl },
        },
        sanitySeo: { seoTitle, seoDescription, seoImage },
    } = useStaticQuery(getData);

    return (
        <Helmet title={seoTitle}>
            <html lang="en" />
            <meta name="theme-color" content="#ffffff" />
            {seoTitle && <meta name="title" content={seoTitle} />}
            {seoDescription && <meta name="description" content={seoDescription} />}

            <meta property="og:type" content="website" />
            {siteUrl && <meta property="og:url" content={siteUrl} />}
            {seoTitle && <meta property="og:title" content={seoTitle} />}
            {seoDescription && <meta property="og:description" content={seoDescription} />}
            {seoImage && <meta property="og:image" content={seoImage.asset.url} />}

            <meta property="twitter:card" content="summary_large_image" />
            {siteUrl && <meta property="twitter:url" content={siteUrl} />}
            {seoTitle && <meta property="twitter:title" content={seoTitle} />}
            {seoDescription && <meta property="twitter:description" content={seoDescription} />}
            {seoImage && <meta property="twitter:image" content={seoImage.asset.url} />}
        </Helmet>
    );
};

export default Seo;

const getData = graphql`
    {
        site {
            siteMetadata {
                siteUrl
            }
        }
        sanitySeo {
            seoTitle
            seoDescription
            seoImage {
                asset {
                    url
                }
            }
        }
    }
`;
