import React, { useState, useEffect } from "react";
import * as style from "./loading.module.css";
import { motion } from "framer-motion";
import { useWindowSize } from "../../hooks/useWindowSize";

const Loading = () => {
    const [state, setState] = useState(true);
    const { windowHeight } = useWindowSize();
    useEffect(
        () =>
            document.documentElement.style.setProperty(
                "--app-height",
                `${window.innerHeight / 100}px`
            ),
        [windowHeight]
    );
    useEffect(() => {
        let timer = setTimeout(() => setState(false), 1500);
        return () => clearTimeout(timer);
    }, []);
    const variants = {
        visible: { opacity: 1 },
        hidden: { opacity: 0, transitionEnd: { display: "none" } },
    };
    return (
        <motion.button
            variants={variants}
            animate={state ? "visible" : "hidden"}
            className={style.loading}
            onClick={() => setState(false)}
        >
            <h1 className={style.text}>Cameo Studios</h1>
        </motion.button>
    );
};

export default Loading;
